import React, {useReducer, createContext} from 'react'
import defaultLang from '../translation/vars'

const GlobalStateContext = createContext();
const GlobalDispatchContext = createContext();

const initialState = {
    language: defaultLang.pt
}

function reducer(state, action){
    switch (action.type) {
        case "TOGGLE_LANGUAGE":{
            return {
                ...state,
                language: state.language === defaultLang.pt ? defaultLang.en : defaultLang.pt
            }
        }
    
        default:
            throw new Error('Bad Action Type')
    }
}

const GlobalContextProvider = ({children}) =>{
    const [state, dispatch] = useReducer(reducer, initialState)
    return (
    <GlobalStateContext.Provider value={state}>
        <GlobalDispatchContext.Provider value={dispatch}>
            {children}
        </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
    )
}

export default GlobalContextProvider

export {
    GlobalStateContext,
    GlobalDispatchContext
}