const defaultLang = {
    pt: {
        current: 'portuguese',
        menu: [
            {text: 'Home', link: '/'},
            {text: 'Como Funciona', link: '/#about'},
            {text: 'Institucional', link: '/#institutional'},
            {text: 'Gestores', link: '/#gestores'},
            {text: 'Notícias', link: '/news/'},
            {text: 'Contato', link: '/contact/'},
            {text: 'FAQ', link: '/faq/'},
            {text: 'Termos', link: '/terms/'},
            {text: 'Privacidade', link: '/privacy/'},
            {text: 'Mapa', link: '/map/'},
        ],
        home: {
            about:{
                title: 'O app da cidadania.',
                text1: 'Reporte os problemas de sua cidade pelo celular.',
                text2: 'O maior banco de dados de zeladoria urbana fortalece',
                text3: ' a voz do munícipe.',
                text4: 'Todo mundo ganha.',
                more: 'Saiba Mais'
            },
            hiw:{
                title: 'Como funciona',
                text: [
                    'Baixe QZela na App Store ou Google Play. Depois de um rápido cadastro, você pode apontar os problemas de zeladoria em qualquer lugar com internet, diretamente do seu celular. Leva menos do que 30 segundos.',
                    'QZela tem cerca de 40 segmentos de zeladoria urbana, detalhadas em mais de 400 tipos de problemas que afetam a vida na cidade, e você mesmo poderá apontar.',
                    'No seu perfil QZela, você visualiza todas as suas ocorrências e o status de cada uma delas.Todas as atividades geram pontos, que poderão ser revertidos em prêmios e vantagens.',
                ]
            },
            testimony: [
                {
                    title: 'Inovação',
                    text: 'Uma política pública inteligente, diretamente em suas mãos. QZela baseia-se no conceito de política pública aplicada a tecnologias avançadas com interface simples e amigável, para que o cidadão participe ativamente da zeladoria urbana.',
                    img: 'https://storage.googleapis.com/assets.qzela.com.br/images/landing/tecnologias/inovacao.png',
                    btn: 'Veja nosso vídeo institucional'
                },
                {
                    title: 'Tecnologia',
                    text: `QZela utiliza recursos de inteligência artificial para a gestão de informações. Com isso, garante a credibilidade e consistência de dados, sejam eles gerados pelo munícipe ou pelo próprio fiscal da administração pública.`,
                    img: 'https://storage.googleapis.com/assets.qzela.com.br/images/landing/tecnologias/tecnologia.png',
                    btn: 'Veja nosso vídeo institucional'
                },
                {
                    title: 'Transparência',
                    text: 'QZela preza pela construção de uma base de dados extremamente confiável, que gera informações valiosas para a gestão de serviços públicos. QZela Corp permite a integração com os sistemas de seus clientes através de APIs, facilitando a programação de serviços e garantindo mais eficácia com redução custos.',
                    img: 'https://storage.googleapis.com/assets.qzela.com.br/images/landing/tecnologias/transparencia.png',
                    btn: 'Veja nosso vídeo institucional'
                }
            ],
            insitutional:{
                title: 'Vídeo Institucional',
                text: 'Fique por dentro das funcionalidades e utilidades do QZela com exemplos de utilização pela cidade.'
            },
            managers: {
                title: 'QZela Corp para Gestores',
                text: 'Um serviço inédito e eficiente para redução substancial de custos',
                private: 'Privados',
                public: 'Públicos'
            }
        },
        filter: "Filtro",
        blog: ['Publicado por Cleci Leão.'],
        blogBoss: ['Publicado por Robero Badra.'], 
        tags: ["Cases", "Cidadania", "Cidades Inteligentes", "Corona Vírus", "Gestão Eficiente", "Meio Ambiente", "Mobilidade Urbana", "Saúde e Bem-estar", "Segurança", "Tecnologia"],
        noResults: "Nenhum resultado encontrado.",
        prevPage: "Página Anterior",
        nextPage: "Próxima Página",
        managers:{
            _private:{
                title: 'Concessionárias e Prestadores de Serviço',
                text: [
                    "QZela utiliza os recursos da mobilidade por meio da tecnologia para construir um banco de dados e referências consistentes para a zeladoria urbana.",
                    "Por meio de inteligência artificial, QZela está apto a oferecer a governos, concessionárias e prestadoras de serviços públicos um serviço inédito, eficiente e com redução substancial de custos.",
                    "Com QZela, o gestor terá um mapa completo dos problemas de zeladoria sob sua responsabilidade. O sistema dispõe de informações atualizadas em tempo real, por meio de um aplicativo colaborativo, com ampla participação da população.",
                    "Em QZela Corp, é possível acompanhar, por meio de relatórios atualizados em tempo real, todas as demandas feitas na sua área e que estão sob sua responsabilidade. Com informação completa, é possível reduzir custos e planejar melhor o atendimento, além de demonstrar e prestar contas do serviço realizado.",
                    "Somos colaboradores, parceiros, profissionais, cidadãos. Em comum, o desejo de ir além; de construir o futuro em pleno presente. Unimos ideias inovadoras ao mais profundo respeito pelo bem-estar público, trazendo para o setor público nosso conhecimento e experiência na gestão de serviços privados.",
                    "Esse é o espírito do QZela. Conte com a gente.",
                ]
            },
            _public:{
                title: 'Poder Público',
                text: [
                    "QZela oferece uma ferramenta que possibilita ao cidadão reunir informações precisas e em tempo real sobre todos os problemas de zeladoria. Por meio de tecnologia com interface amigável e diretamente no celular de cada munícipe, QZela oferece o melhor controle sobre o que acontece na cidade.",
                    "Assim, o gestor público pode administrar melhor a cidade, seja diretamente ou pelo monitoramento do trabalho de terceiros. Em tempo real, e de forma transparente, é possível saber tudo o que está sendo apontado como problema. A ferramenta permite ainda ao gestor estimar custos com mais precisão, além de medir a performance e cobrar resultados.", 
                    "Com os relatórios produzidos em tempo real no back end do QZela, a gestão pública é facilitada, com economia e mais eficiência.",
                    "Todos ganham, afinal."
                ]
            },
            contact:{
                title: 'Deseja saber mais?',
                btn: 'Fale conosco'
            },
            corp:{
                title: 'Já é cliente QZela?',
                btn: 'QZela Corp'
            },
            back: 'Voltar'
        },
        contact:{
            title: 'Entre em Contato',
            name: 'Nome',
            email:'E-mail',
            lblSubject: 'ASSUNTO',
            subject: {
                normal: 'Contato',
                commercial: 'Comercial',
                press: 'Imprensa'
            },
            company: 'Empresa',
            message:'Mensagem',
            send: 'Enviar'
        },
        faq:{
            title: 'FAQ',
            text: [
                {
                    question: "Como baixar QZela?",
                    answer: "Caso você possua um aparelho Apple (iPhone), clique no ícone da Apple Store e digite QZela. Você logo verá o ícone para baixar. Se preferir utilizar o link direto, pode clicar aqui, diretamente do seu celular.",
                },
                {
                    question: "Não consigo acessar o app. O que posso fazer?",
                    answer: "Verifique se a conexão de internet está ativa no local onde você está. Verifique se a rede de conexão de internet onde você está tem algum tipo de bloqueio. Já realizou o seu cadastro inicial? Você faz isso apenas uma vez, no seu primeiro acesso, e depois já consegue utilizar o aplicativo.",
                },
                {
                    question: "Depois de baixado, como abro ocorrências?",
                    answer: "Na tela inicial de QZela, há um botão vermelho com um sinal de +. Ao clicar nesse botão, você já acessa o menu de categorias de problemas e seleciona aquelas que deseja reportar. Em seguida, registra uma foto ou vídeo de sua ocorrência, e envia. Pronto! Sua ocorrência já está aberta.",
                },
                {
                    question: "Em quais dispositivos posso usar QZela?",
                    answer: "QZela funciona exclusivamente em celulares. Tablets, PCs e Notebooks não estão habilitados para o uso do aplicativo.",
                },
                {
                    question: "Por que devo preencher meu CPF no cadastro de QZela?",
                    answer: "Há três motivos principais pelos quais QZela solicita o preenchimento do CPF. Para garantir a credibilidade das informações; para garantir a sua segurança, de modo que outro usuário não utilize seus dados; para vincular seu nome ao sistema de pontuação quando houver prêmios ou vantagens oferecidas aos usuários.",
                },
                {
                    question: "Para que serve a tela “meu perfil”?",
                    answer: "O aplicativo QZela é mais do que um canal de comunicação com mapeamento da cidade: ele possibilita que o próprio munícipe acompanhe as ocorrências que abriu, com data, foto e informações adicionais. Na tela “Meu Perfil”, o usuário vai encontrar as ocorrências com as quais interagiu, além de monitorar o acúmulo de pontos, os chamados “QZelas”.",
                },
                {
                    question: "Para onde vai a informação de minha ocorrência?",
                    answer: "As ocorrências de QZela são registradas em um banco de dados gerido por inteligência artificial, ao qual os gestores públicos poderão ter acesso ao contatar a empresa desenvolvedora de QZela. As ocorrências são enviadas aos gestores de maneira anônima. Todo e qualquer dado pessoal do usuário é protegido por lei.",
                },
                {
                    question: "Qual a cobertura de QZela?",
                    answer: "QZela funciona em todo o território nacional neste primeiro momento, mas o sistema está dimensionado para funcionar em todo os paises.",
                },
                {
                    question: "Como encontrar um problema na lista do app?",
                    answer: "Você pode visualizar as categorias navegando pelas telas do app, ao deslizar as telas lateralmente. Caso prefira, pode também digitar o problema que quer registrar, ou então clicar no microfone disponível no seu próprio teclado do celular e falar o problema em voz alta, para que o app faça a busca.",
                },
                {
                    question: "Como somar pontos QZela?",
                    answer: 'Todas as interações no aplicativo somam pontos, os chamados QZelas. Os pontos podem ser gerais, ou relacionados a um evento específico, pertencente ao campo "eventos". Veja a equivalência de cada interação: Abrir ocorrência: 5 pontos. Confirmar ocorrência já aberta por outro usuário: 1 ponto. Invalidar ocorrência inexistente ou incorreta, registrada como aberta: 1 ponto. Fechar uma ocorrência aberta e já resolvida: 5 pontos. Avaliar o serviço executado na ocorrência já resolvida e fechada: 1 ponto.',
                },
                {
                    question: "Para que serve a pontuação QZela?",
                    answer: "QZela irá reverter a pontuação de usuários em prêmios, vantagens e descontos. Continue somando pontos! Assim que houver novidades, você será o primeiro a saber!",
                },
                {
                    question: "O que acontece quando o usuário abre uma ocorrência inválida?",
                    answer: "A ocorrência inválida será identificada por inteligência artificial, e o usuário logo receberá uma comunicação informando sobre o cancelamento de sua ocorrência.",
                },
                {
                    question: "O que faz com que uma ocorrência seja considerada inválida?",
                    answer: "São consideradas inválidas as ocorrências inconsistentes ou inapropriadas: fotos que não identifiquem o problema apontado (como por exemplo reportar um semáforo quebrado e enviar a foto de um buraco, ou de um objeto aleatório), comentários ou imagens inapropriados, exposição de pessoas sem autorização, endereço não condizente com a geolocalização ou inexistência da categoria no local demarcado (como, por exemplo, indicar “praia poluída” em uma cidade sem praia).",
                },
                {
                    question: "O que acontece quando alguém invalida minha ocorrência?",
                    answer: "Caso a sua ocorrência esteja ativa, porém contenha alguma inconsistência, o sistema irá avaliar e conferir novamente os dados. Caso seja considerada, de fato, invalidada, a sua ocorrência será excluida e a pontuação será revertida.",
                },
                {
                    question: "Não consigo registrar uma ocorrência, pois ela consta como “existente”.",
                    answer: "Para evitar a duplicidade de dados, cada ocorrência somente poderá ser aberta uma vez. Mas não se preocupe! Você ainda pode confirmar uma ocorrência aberta, e ganhar pontos com essa ação.",
                },
                {
                    question: "O que acontece quando alguém confirma minha ocorrência?",
                    answer: "Ter a ocorrência confirmada aumenta a relevância do problema apontado, e as ocorrências vão ganhando prioridade na seleção eletrônica realizada por inteligência artificial.",
                },
                {
                    question: "Por que confirmar ocorrências abertas por outra pessoa?",
                    answer: "Ter a ocorrência confirmada aumenta a relevância do problema apontado, e as ocorrências vão ganhando prioridade na seleção eletrônica realizada por inteligência artificial.",
                },
                {
                    question: "Por que fechar ocorrências?",
                    answer: "Fechar ocorrências abertas e resolvidas gera pontos e contribui com a informação aos outros munícipes. Mas atenção: feche apenas as ocorrências cujo problema já tenha sido solucionado. Caso contrário, sua confirmação não será aceita pelo sistema de inteligência artificial, e sua pontuação pode cair.",
                },
                {
                    question: "Como saber se a ocorrência já foi solucionada?",
                    answer: "Quando você for o gerador da ocorrência, assim que ela for fechada, o sistema vai notificá-lo. Para as suas ocorrências ainda aguardando resolução, é possível verificar o seu status no seu histórico do aplicativo. Ocorrências geradas por outros usuários podem ser consultadas sempre que você estiver nas proximidades do local.",
                },
                {
                    question: "Por que avaliar o serviço executado em ocorrências fechadas?",
                    answer: "A sua avaliação será informada aos gestores, garantindo sempre a melhoria da prestação de serviços, por meio da qualidade do trabalho executado. Além disso, avaliar ocorrências resolvidas gera pontos.",
                },
                {
                    question: "Como obter acesso ao banco de dados de QZela?",
                    answer: "Por trás da interface do aplicativo gratuito QZela, disponibilizado ao cidadão, existe uma plataforma de serviços para o gestor: o QZela Corp. O acesso a esses dados pode ser contratado por pessoa física ou jurídica, após um estudo conjunto e personalizado sobre quais informações o cliente deseja obter. Entre em contato conosco e peça um orçamento!",
                },
                {
                    question: "O que é QZela Corp?",
                    answer: "QZela Corp é uma plataforma de gestão de dados de zeladoria que qualifica, classifica e organiza os dados gerados pelo aplicativo QZela por meio de Inteligência Artificial. O acesso a esses dados pode ser contratado por pessoa física ou jurídica, após um estudo conjunto e personalizado sobre quais informações o cliente deseja obter. Entre em contato conosco e peça um orçamento!",
                },
                {
                    question: "Como elaborar relatórios em QZela Corp?",
                    answer: "Ao aderir à plataforma QZela Corp, o usuário recebe instruções detalhadas sobre como utilizar dados e elaborar relatórios, gráficos, tabelas ou listas de acordo com a sua necessidade de gestão e operações.",
                },
                {
                    question: "Qual a função do campo Eventos do app?",
                    answer: "De acordo com demandas específicas, QZela pode promover ações individualmente, ou em parceria com empresas, associações, organizações ou outras comunidades. Ao inscrever-se como participante do evento, o usuário pode se beneficiar de pontuação exclusiva da ação na medida em que interage no aplicativo."
                }
            ],
            more: 'Deseja saber mais?',
            contact: 'Fale Conosco'
        },
        privacy:{
            title: 'Política de Privacidade do QZela',
            terms:[
                {
                    title: 'INFORMAÇÕES IMPORTANTES',
                    text: [
                        'A POLÍTICA DE PRIVACIDADE (“POLÍTICA DE PRIVACIDADE”) que você está acessando integra os TERMOS E CONDIÇÕES DE USO e descreve como a WESTARS TG SERVIÇOS DE INFORMÁTICA LTDA. (“WESTARS TECHNOLOGY GROUP”) realiza a coleta, armazenamento, uso, processamento, associação, proteção, compartilhamento e demais formas de tratamento das suas informações, incluindo os seus dados pessoais, em conexão com o APLICATIVO e os SERVIÇOS regidos pelos TERMOS E CONDIÇÕES DE USO.',
                        'Esta POLÍTICA DE PRIVACIDADE estabelece as condições de tratamento de seus dados, necessárias para a operação do APLICATIVO QZELA. Caso não concorde com alguma disposição desta POLÍTICA DE PRIVACIDADE, você não deverá utilizar o Aplicativo QZELA. Você está plenamente ciente e concorda de forma expressa que a WESTARS TECHNOLOGY GROUP poderá coletar, armazenar, processar, associar, compartilhar, utilizar, divulgar, ou de qualquer outro modo tratar suas informações, incluindo seus dados pessoais, sob qualquer das formas e para todas as finalidades previstas nesta POLÍTICA DE PRIVACIDADE.',
                        'As regras estabelecidas nesta POLÍTICA DE PRIVACIDADE são restritas ao uso do APLICATIVO QZELA e dos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO, fornecidos exclusivamente pela WESTARS TECHNOLOGY GROUP no Brasil.',
                        'Ao utilizar o APLICATIVO e SERVIÇOS fornecidos pela WESTARS TECHNOLOGY GROUP, você estará concordando integralmente com as regras desta POLÍTICA DE PRIVACIDADE, razão pela qual recomendamos que a leia com atenção e, no caso de dúvidas, entre em contato conosco através da pagina de contato em nosso site ou pelas midias sociais.',
                    ]
                },
                {
                    title: '1. INFORMAÇÕES COLETADAS:',
                    text: [
                        'Quando você se cadastra e utiliza os SERVIÇOS, você fornece e a WESTARS TECHNOLOGY GROUP coleta informações estatísticas de utilização dos SERVIÇOS, dados agregados e dados de identificação pessoal. Essas informações passam a ser tratadas conforme esta POLÍTICA DE PRIVACIDADE e podem ser utilizadas nas situações e para as finalidades descritas no ITEM 3 abaixo.',
                        'A WESTARS TECHNOLOGY GROUP pode coletar suas informações, incluindo dados aptos a identificá-lo, em diversas circunstâncias, tais como: quando da criação de seu cadastro na WESTARS TECHNOLOGY GROUP, quando você acessa o APLICATIVO, ou utiliza os serviços da WESTARS TECHNOLOGY GROUP.',
                        '>Ao se cadastrar na WESTARS TECHNOLOGY GROUP, você fornece determinadas informações, que podem incluir o seu nome, endereço, número de telefone, e-mail, número do CPF, sexo, data de nascimento, RG e comprovante de residência. Outras informações podem ser coletadas e processadas pela WESTARS TECHNOLOGY GROUP em razão de normas legais que lhe sejam aplicáveis.',
                        '>A WESTARS TECHNOLOGY GROUP também requer que você conceda permissão de acesso à sua câmera, localização aproximada (ininterrupta), armazenamento, telefone, nome de usuário e senha.',
                        '>Conforme você usa o APLICATIVO QZELA e os SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO, informações adicionais relativas a você podem ser obtidas pela WESTARS TECHNOLOGY GROUP, como a sua posição geográfica, endereço IP, navegador utilizado, informações de cookies, tipo e marca do aparelho celular, identificadores de dispositivos móveis, versão do sistema operacional, aplicativos instalados, informações sobre rede, provedor de conexão à Internet utilizado, configuração dos dispositivos, dados de software, itinerários, as avaliações que você faz sobre os SERVIÇOS da WESTARS TECHNOLOGY GROUP e as formas de comunicação com a WESTARS TECHNOLOGY GROUP e/ou com terceiros.',
                        '>Caso você não forneça qualquer das informações solicitadas, a WESTARS TECHNOLOGY GROUP não poderá garantir a qualidade e precisão do APLICATIVO QZELA e dos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO. Nesse caso, a WESTARS TECHNOLOGY GROUP terá o direito de excluir você do APLICATIVO QZELA imediatamente, inclusive para assegurar o funcionamento regular e seguro do APLICATIVO QZELA e dos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO para os demais usuários.',
                        '>A WESTARS TECHNOLOGY GROUP também pode obter informações sobre você provenientes de bancos de dados públicos e privados, parceiros comerciais e/ou de outros prestadores de serviço quando você vincular sua CONTA a esses prestadores (“TERCEIROS”), desde que tais TERCEIROS tenham obtido as informações em conformidade com a legislação aplicável. Essas informações poderão ser associadas às demais informações que a WESTARS TECHNOLOGY GROUP coleta sobre você e também utilizadas nas situações e para as finalidades descritas no ITEM 3 abaixo. As informações fornecidas por TERCEIROS à WESTARS TECHNOLOGY GROUP obedecem à disciplina própria estabelecida por tais TERCEIROS e poderão estar sujeitos às suas respectivas políticas de privacidade.'
                    ]
                },
                {
                    title: '2. PROTEÇÃO E ARMAZENAMENTO:',
                    text: [
                        'A WESTARS TECHNOLOGY GROUP emprega práticas de segurança da informação adequadas em todos os seus sistemas e infraestrutura, incluindo medidas físicas e digitais de segurança visando garantir a proteção de suas informações. Essas medidas incluem controles de acessos, criptografia forte, implementação SSL, firewalls, registro de log de alteração, duplo fator de autenticação, entre outros mecanismos e protocolos.',
                        'As informações coletadas pela WESTARS TECHNOLOGY GROUP podem ser armazenadas em servidores localizados no Brasil ou no exterior, a exclusivo critério da WESTARS TECHNOLOGY GROUP. Você declara estar ciente e de acordo que outros países podem ter níveis de proteção de dados diferentes do Brasil. Sem prejuízo, suas informações porventura armazenadas em outros países estarão sujeitas a medidas de segurança pelo menos equivalentes àquelas descritas no parágrafo anterior.',
                        'As medidas de segurança acima descritas aplicam-se às suas informações somente a partir do momento em que a WESTARS TECHNOLOGY GROUP as recebe e enquanto as mantém sob sua guarda. O funcionamento e a segurança do dispositivo que você usa para acessar o APLICATIVO QZELA e os SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO, bem como da rede pela qual os dados trafegam não são de responsabilidade da WESTARS TECHNOLOGY GROUP, cabendo a você, usuário, adotar e respeitar precauções básicas de segurança.',
                    ]
                },
                {
                    title: '3. USO E COMPARTILHAMENTO:',
                    text: [
                        'As suas informações, incluindo seus dados pessoais, são utilizadas, dentre outras finalidades, para permitir o funcionamento e aprimorar o APLICATIVO QZELA e os SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO.',
                        'A WESTARS TECHNOLOGY GROUP utilizará as suas informações especificamente para as seguintes finalidades: A) prestar, fornecer, manter, desenvolver, aprimorar, divulgar e ampliar a utilização do APLICATIVO QZELA e dos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO; B) identificar você e personalizar a sua experiência e utilização do APLICATIVO QZELA e dos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO; C) gerar análises e relatórios estatísticos sobre o funcionamento e operação do APLICATIVO QZELA e dos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO, em benefício da WESTARS TECHNOLOGY GROUP e/ou dos seus parceiros; D) possibilitar que a WESTARS TECHNOLOGY GROUP entre em contato com você a respeito do APLICATIVO QZELA e dos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO, bem como para prestar suporte ao usuário; E) disponibilizar e melhorar mecanismos de segurança e monitoramento do APLICATIVO QZELA e dos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO; F) detectar e proteger a WESTARS TECHNOLOGY GROUP e os usuários contra fraudes, abusos ou atos ilegais; G) detectar e impedir o uso de aplicativos e programas para propósitos fraudulentos ou que alterem funcionalidades do APLICATIVO QZELA e dos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO; H) desenvolver novos produtos, serviços e funcionalidades; I) realizar pesquisas e análises científicas; e J) cumprir com obrigações legais de coleta e compartilhamento de dados com o poder público, na forma da legislação e regulamentação aplicáveis.',
                        'Os seus dados de contato são utilizados para a WESTARS TECHNOLOGY GROUP se comunicar diretamente com você e para enviar informações relevantes, incluindo avisos sobre o uso do APLICATIVO QZELA e dos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO.',
                        'A WESTARS TECHNOLOGY GROUP pode utilizar outras informações sobre você disponíveis a partir de fontes terceiras e combiná-las com as informações coletadas diretamente de você em conformidade com esta POLÍTICA DE PRIVACIDADE, para todas as finalidades previstas neste ITEM. Nessa hipótese, somente as informações coletadas diretamente pela WESTARS TECHNOLOGY GROUP de você e o resultado da combinação são abrangidos por e sujeitos a esta POLÍTICA DE PRIVACIDADE.',
                        'A WESTARS TECHNOLOGY GROUP não compartilhará nem fornecerá a terceiros as suas informações, incluindo os seus dados pessoais, exceto: A) quando você requerer ou autorizar à WESTARS TECHNOLOGY GROUP o compartilhamento; B) para viabilizar o fornecimento e a operação do APLICATIVO QZELA e dos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO de maneira adequada e na forma que você contratou; C) a parceiros comerciais que prestem serviços ou trabalhem em nome da WESTARS TECHNOLOGY GROUP, de acordo com as especificações e diretrizes determinadas pela WESTARS TECHNOLOGY GROUP, incluindo previsão contratual de dever de manutenção da confidencialidade das informações por esses terceiros; D) a órgãos, autoridades e demais entes do poder público, para o atendimento da legislação e regulamentação aplicáveis ao APLICATIVO QZELA e/ou aos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO; E) a órgãos, autoridades e demais entes do poder público, bem como a pessoas físicas ou jurídicas de natureza privada, em cumprimento de obrigação legal ou de ordem judicial; F) à autoridade policial ou ao representante do Ministério Público, independentemente da existência de obrigação legal específica ou ordem judicial, quando, a critério da WESTARS TECHNOLOGY GROUP, houver suspeita, flagrante ou investigação de crime com violência ou grave ameaça envolvendo usuários do APLICATIVO QZELA, ou necessário para proteger direitos, propriedades ou a segurança da WESTARS TECHNOLOGY GROUP, de seus usuários ou de terceiros; G) para o exercício e defesa de quaisquer direitos da WESTARS TECHNOLOGY GROUP, ao seu critério, incluindo no âmbito de processos judiciais ou administrativos; e H) em caso de alteração da propriedade ou controle da WESTARS TECHNOLOGY GROUP, decorrente de fusão, aquisição ou venda de ativos.'
                    ]
                },
                {
                    title: '4. CONSENTIMENTO:',
                    text: [
                        'A WESTARS TECHNOLOGY GROUP oferece seus SERVIÇOS sujeitos aos TERMOS E CONDIÇÕES DE USO e à POLÍTICA DE PRIVACIDADE e ela será aplicável enquanto você utilizar o APLICATIVO QZELA e os SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO.',
                        'Ao instalar o APLICATIVO QZELA e realizar seu cadastro você concorda automaticamente com os TERMOS E CONDIÇÕES DE USO e com a POLÍTICA DE PRIVACIDADE. ISSO SIGNIFICA QUE VOCÊ CONSENTE EXPRESSAMENTE COM A COLETA, ARMAZENAMENTO, USO, PROCESSAMENTO, ASSOCIAÇÃO, COMPARTILHAMENTO, DIVULGAÇÃO E OUTROS MODOS DE TRATAMENTO DAS SUAS INFORMAÇÕES, INCLUINDO DADOS PESSOAIS, SOB QUALQUER DAS FORMAS E PARA TODAS AS FINALIDADES PREVISTAS NESTA POLÍTICA DE PRIVACIDADE.',
                        'Alterações futuras desta POLÍTICA DE PRIVACIDADE que sejam consideradas relevantes, ou seja, que reduzam ou alterem direitos atribuídos a você, serão comunicadas pela WESTARS TECHNOLOGY GROUP com antecedência prévia adequada. Nesse caso, se você não concordar com o teor da nova POLÍTICA DE PRIVACIDADE, as suas informações continuarão sendo tratadas em conformidade com a versão anterior pelo prazo de aviso prévio concedido, durante o qual você poderá cessar o uso do APLICATIVO QZELA e dos SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO. Se você continuar utilizando o APLICATIVO QZELA e/ou os SERVIÇOS descritos nos TERMOS E CONDIÇÕES DE USO após o término do prazo de aviso prévio, você concordará integralmente e automaticamente com a nova POLÍTICA DE PRIVACIDADE, que passará a reger o tratamento das suas informações.'
                    ]
                },
                {
                    title: '5. EXCLUSÃO DE DADOS PESSOAIS:',
                    text: [
                        'Se você desejar excluir seus dados pessoais sob a guarda da WESTARS TECHNOLOGY GROUP, você deve solicitar a exclusão diretamente à WESTARS TECHNOLOGY GROUP através da pagina de contato em nosso site.',
                        'Para proceder a essa exclusão, você deve deletar o APLICATIVO QZELA e entrar em contato com a WESTARS TECHNOLOGY GROUP para solicitar que suas informações sejam definitivamente eliminadas. Portanto, não basta desinstalar o APLICATIVO QZELA para que suas informações sejam excluídas.',
                        'Em observância à legislação aplicável ou cumprimento de ordem judicial, a WESTARS TECHNOLOGY GROUP poderá manter determinados dados seus armazenados mesmo após o seu pedido de exclusão.',
                        'A WESTARS TECHNOLOGY GROUP armazenará a sua solicitação de exclusão e, observado o prazo legal de guarda obrigatória de alguns determinados dados, providenciará a destruição ou anonimização, a critério exclusivo da empresa, das informações capazes de identificar você.',
                        'A presente POLÍTICA DE PRIVACIDADE será interpretada integralmente segundo a legislação brasileira, no idioma português, sendo eleito o Foro da Comarca de São Paulo, Estado de São Paulo, Brasil, para dirimir eventual litígio, questão ou dúvida superveniente, com expressa renúncia a qualquer outro, por mais privilegiado que seja ou se torne.',
                        'Em caso de dúvidas e sugestões, entre em contato com a WESTARS TECHNOLOGY GROUP através da pagina de contato em nosso site ou pelas midias sociais.',
                        'Última atualização: 01/08/2019'
                    ]
                }
            ]
        },
        terms:{
            title: 'Termos e Condições de Uso do QZela',
            content: [
                {
                    title: '1. INTRODUÇÃO E DISPOSIÇÕES GERAIS:',
                    text: [
                        '1.1. Estes são os TERMOS DE USO (“TERMOS”) que regem o acesso e uso, dentro do Brasil, dos serviços prestados por intermédio de aplicação tecnológica (“APLICATIVO”), sítios da Internet e conteúdos relacionados (“SERVIÇO(S)”), disponibilizados pela WESTARS TG SERVIÇOS DE INFORMÁTICA LTDA. (“WESTARS TECHNOLOGY GROUP”), inscrita no CNPJ sob o nº 33.286.389/0001-32, com sede na Rua Flórida, 1.758, cj 91 - J. Monções, CEP 04565-001, São Paulo, SP, a toda e qualquer pessoa física que utilize o APLICATIVO e/ou os SERVIÇOS (“USUÁRIO”).',
                        '1.2. ACEITAÇÃO DOS TERMOS E CONDIÇÕES DE USO: AO SE CADASTRAR E UTILIZAR CONTINUAMENTE O APLICATIVO E OS SERVIÇOS, O USUÁRIO DECLARARÁ, DE FORMA EXPRESSA E INEQUÍVOCA, TER LIDO E ACEITO OS TERMOS. CASO, A QUALQUER TEMPO, O USUÁRIO NÃO CONCORDE COM OS TERMOS, DEVERÁ CESSAR IMEDIATAMENTE A UTILIZAÇÃO DO APLICATIVO E DESINSTALÁ-LO DE SEU APARELHO.',
                        '1.3. COMUNICAÇÃO COM O USUÁRIO: O USUÁRIO autoriza a WESTARS TECHNOLOGY GROUP a enviar notificações administrativas no APLICATIVO (“push”), por e-mail, SMS, em uma publicação em seu site ou por qualquer outro meio de comunicação disponível (“MEIOS DE COMUNICAÇÃO”) com conteúdo de natureza informativa e promocional relacionados aos SERVIÇOS.',
                        '1.4. ALTERAÇÃO DOS TERMOS: A WESTARS TECHNOLOGY GROUP se reserva o direito de realizar alterações e atualizações nos TERMOS, a qualquer momento, sem a necessidade de aviso prévio. Em caso de alterações dos TERMOS que restrinjam direitos dos USUÁRIOS, a WESTARS TECHNOLOGY GROUP comunicará tal alteração, ao seu critério, por intermédio dos MEIOS DE COMUNICAÇÃO. Todavia, esta liberalidade não afasta a responsabilidade do USUÁRIO de verificar periodicamente os TERMOS. Caso o USUÁRIO não concorde com as alterações dos TERMOS, deverá cancelar sua conta, cessar toda e qualquer utilização dos SERVIÇOS e desinstalar o APLICATIVO do seu aparelho. O fato do USUÁRIO continuar a acessar ou usar os SERVIÇOS após essa postagem representa seu consentimento em vincular-se aos TERMOS alterados.'
                    ]
                },
                {
                    title: '2. DADOS DO USUÁRIO E PRIVACIDADE:',
                    text: [
                        '2.1. A WESTARS TECHNOLOGY GROUP possui uma política expressa sobre privacidade. As informações de registro e quaisquer outras informações sobre o USUÁRIO estão sujeitas ao tratamento mencionado em tal política de privacidade. O USUÁRIO está plenamente ciente e concorda que o uso do APLICATIVO e a prestação do SERVIÇO envolvem a coleta e a utilização de informações e dados sobre o USUÁRIO (conforme definido na POLÍTICA DE PRIVACIDADE, que constitui parte integrante deste TERMO, E QUE DEVERÁ SER CONSULTADA PELO USUÁRIO NO SEGUINTE LINK, incluindo a transferência destas informações e dados para outros territórios, para fins de armazenamento, processamento e utilização pela WESTARS TECHNOLOGY GROUP, visando a prestação do serviço e demais finalidades previstas na POLÍTICA DE PRIVACIDADE.'
                    ]
                },
                {
                    title: '3. CADASTRO:',
                    text: [
                        '3.1. Para utilizar o APLICATIVO, o USUÁRIO deve registrar-se e manter uma conta pessoal de USUÁRIO (“CONTA”). O USUÁRIO deve ter capacidade civil, tendo idade mínima de 18 anos de idade para criar seu perfil, a menos que seja emancipado ou tenha obtido plena capacidade civil nos termos da legislação civil em vigor. Pais e responsáveis legais são responsáveis por avaliar a adequação da utilização de dispositivos móveis e dos SERVIÇOS para menores de 18 anos de idade. O USUÁRIO que desejar utilizar o SERVIÇO deverá obrigatoriamente preencher os campos de cadastro exigidos e responderá pela veracidade das informações declaradas, obrigando-se a manter informações válidas, atualizadas e corretas. O perfil do USUÁRIO é exclusivo e intransferível. Em caso de não confirmação de seus dados, o acesso do USUÁRIO ao SERVIÇO poderá ser bloqueado, a exclusivo critério da WESTARS TECHNOLOGY GROUP.',
                        '3.2. As informações da CONTA são de exclusiva responsabilidade de quem as inseriu. No caso de acarretarem danos ou prejuízos de qualquer espécie, as medidas cabíveis podem ser tomadas pela WESTARS TECHNOLOGY GROUP a fim de resguardar seus interesses e a integridade dos demais USUÁRIOS do APLICATIVO.'
                    ]
                },{
                    title: '4. SERVIÇOS:',
                    text: [
                        '4.1. SERVIÇOS PRESTADOS PELA WESTARS TECHNOLOGY GROUP: Os SERVIÇOS consistem na disponibilização de uma aplicação tecnológica que possibilita ao USUÁRIO devidamente cadastrado: gerar e monitorar ocorrência de zeladoria urbana. O SERVIÇO não deve ser utilizado para qualquer finalidade que não as expressamente autorizadas por estes TERMOS.',
                        '4.1.1. O uso dos SERVIÇOS pelos USUÁRIOS é, em geral, gratuito. A WESTARS TECHNOLOGY GROUP informará previamente o USUÁRIO sobre funcionalidades e condutas que possam gerar cobranças e/ou pagamentos pelo USUÁRIO de forma específica pelos MEIOS DE COMUNICAÇÃO e/ou por atualização dos TERMOS. A WESTARS TECHNOLOGY GROUP se reserva o direito de passar a cobrar pelo SERVIÇO ou parte dele a qualquer tempo. O USUÁRIO será previamente informado caso isso ocorra e terá a oportunidade de consentir com tais cobranças pelo SERVIÇO ou cessar o uso do mesmo.',
                        '4.1.2. O USUÁRIO declara que está plenamente e ciente e concorda que a WESTARS TECHNOLOGY GROUP não presta e não assegura a prestação de qualquer serviço público, exercido, direta ou indiretamente, pelos entes federais, estaduais, municipais e/ou distritais, prestando, exclusivamente, um serviço de intermediação, voltado à facilitação da interação entre USUÁRIOS e entidades ou órgãos públicos, concessionárias de serviços públicos ou organizações de sociedade civil responsáveis pelo gerenciamento das demandas dos cidadãos.',
                        '4.1.3. O gerenciamento e a solução das demandas dos cidadãos, Usuários do Aplicativo e dos Serviços, competem única e exclusivamente às entidades ou órgãos públicos, concessionárias de serviços públicos ou organizações de sociedade civil pertinentes, não tendo a WESTARS TECHNOLOGY GROUP responsabilidade de qualquer natureza e/ou espécie.',
                        '4.2. A WESTARS TECHNOLOGY GROUP disponibiliza ao USUÁRIO a opção de avaliar e comentar qualitativamente o serviço prestado pelo ente público correspondente, sendo vedada, sob pena de cancelamento do uso do APLICATIVO e dos SERVIÇOS, nos termos da CLÁUSULA 8, a publicação de comentários de caráter difamatório, calunioso, violento, obsceno, pornográfico, racista, homofóbico, ilegal ou de qualquer outra forma ofensivo, assim entendido pela WESTARS TECHNOLOGY GROUP a seu exclusivo critério.',
                        '4.2.1. O conteúdo publicado pelo USUÁRIO é de sua única e exclusiva responsabilidade civil e penal e para todos os efeitos legais, inclusive em relação aos comentários e opiniões acerca de determinado conteúdo.',
                        '4.3. ACESSO À REDE E EQUIPAMENTOS: O USUÁRIO entende e concorda que a utilização do SERVIÇO demanda a aquisição de dispositivos de telefonia móvel e a contratação de serviços de telecomunicação, e que a utilização do SERVIÇO poderá gerar cobranças por parte de tais prestadores de serviço de telecomunicação para conexão com a Internet, por exemplo. O USUÁRIO é o responsável exclusivo por contratar e arcar com todo e qualquer custo e ônus relacionados à aquisição de seu dispositivo de telefonia móvel e à contratação de seu serviço de telecomunicação. A WESTARS TECHNOLOGY GROUP não é responsável pela disponibilidade, qualidade e manutenção de tais serviços de telecomunicação e o USUÁRIO entende que as condições do serviço de telecomunicações poderão afetar a experiência do SERVIÇO. A WESTARS TECHNOLOGY GROUP não será responsabilizada por qualquer problema relacionado ao SERVIÇO decorrente direta ou indiretamente de inconsistências ou falhas nos dispositivos de telefonia móvel e/ou nos serviços de telecomunicação contratados pelo USUÁRIO.',
                        '4.4. MODIFICAÇÃO OU ENCERRAMENTO DO SERVIÇO: A WESTARS TECHNOLOGY GROUP se reserva o direito de, a qualquer tempo, modificar ou descontinuar, temporariamente ou permanentemente, o SERVIÇO ou parte dele, com ou sem notificação. O USUÁRIO concorda que a WESTARS TECHNOLOGY GROUP não será responsabilizada, nem terá qualquer obrigação adicional, implícita ou explícita, para com o USUÁRIO ou terceiros em razão de qualquer modificação, suspensão ou desativação do SERVIÇO.'
                    ]
                },{
                    title: '5. LICENÇA:',
                    text: [
                        '5.1. Sujeito ao cumprimento destes TERMOS, a WESTARS TECHNOLOGY GROUP outorga ao USUÁRIO uma licença, gratuita, limitada, não exclusiva, pessoal, intransferível e revogável a qualquer momento, única e exclusivamente para acessar e utilizar o SERVIÇO para fins pessoais, não comerciais, por meio de qualquer dispositivo compatível.'
                    ]
                },
                {
                    title: '6. EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE:',
                    text: [
                        '6.1. O USUÁRIO DECLARA, DE FORMA EXPRESSA E INEQUÍVOCA, QUE ESTÁ PLENAMENTE CIENTE E CONCORDA QUE: A) A UTILIZAÇÃO DO SERVIÇO ESTÁ SOB INTEIRA RESPONSABILIDADE DO USUÁRIO; B) O SERVIÇO É FORNECIDO AO USUÁRIO NA FORMA EM QUE ESTÁ DISPONÍVEL; C) A WESTARS TECHNOLOGY GROUP NÃO OFERECE QUAISQUER OUTRAS GARANTIAS ALÉM DAQUELAS EXPRESSAMENTE ESTABELECIDAS NESTES TERMOS; E D) A WESTARS TECHNOLOGY GROUP NÃO PODE GARANTIR QUE: (I) O SERVIÇO À DISPOSIÇÃO ATENDERÁ ÀS NECESSIDADES DO USUÁRIO; (II) O SERVIÇO SERÁ PRESTADO DE FORMA ININTERRUPTA OU TEMPESTIVA; (III) QUE O ENTE PÚBLICO RESPONSÁVEL PELA PRESTAÇÃO DOS SERVIÇOS PÚBLICOS DEMANDADOS PELO USUÁRIO O PRESTARÁ DE FORMA TEMPESTIVA E, MUITO MENOS, CONFORME A EXPECTATIVA DO USUÁRIO; E (IV) QUE MELHORAMENTOS OU INOVAÇÕES SERÃO IMPLEMENTADOS.',
                        '6.1.1. O USUÁRIO DECLARA, DE FORMA EXPRESSA E INEQUÍVOCA, QUE ESTÁ PLENAMENTE CIENTE E CONCORDA QUE A WESTARS TECHNOLOGY GROUP NÃO TERÁ QUALQUER RESPONSABILIDADE, CONTRATUAL OU EXTRACONTRATUAL, POR QUAISQUER DANOS PATRIMONIAIS OU MORAIS, INCLUINDO, SEM LIMITAÇÃO, DANOS POR LUCROS CESSANTES OU QUAISQUER OUTRAS PERDAS, DE QUALQUER NATUREZA E/OU ESPÉCIE, RESULTANTES DO: A) USO OU INCAPACIDADE DE USAR O SERVIÇO; B) QUEBRAS DE SEGURANÇA E ACESSO NÃO AUTORIZADO ÀS TRANSMISSÕES OU INFORMAÇÕES DO USUÁRIO, BEM COMO DA ALTERAÇÃO DESTAS; C) ORIENTAÇÕES OU CONDUTAS DE TERCEIROS SOBRE O SERVIÇO; E D) POR MOTIVOS DE FORÇA MAIOR OU DE CASO FORTUITO, OU AINDA, DECORRENTES DE ATOS PRATICADOS PELO PRÓPRIO USUÁRIO /OU DE ATOS PRATICADOS POR OU SOB A RESPONSABILIDADE DE TERCEIROS.',
                        '6.2. O USUÁRIO concorda em indenizar e isentar a WESTARS TECHNOLOGY GROUP e seus diretores e empregados, parceiros e clientes que exibam sua marca, por todas e quaisquer perdas e danos que venham a ser demandados, incluindo, mas não se limitando, despesas judiciais e honorários advocatícios, em razão: A) de qualquer fato ou ato ilícito praticado pelo USUÁRIO; B) do uso dos SERVIÇOS; C) da violação destes TERMOS; ou D) violação de direitos de quaisquer terceiros.'
                    ]
                },{
                    title: '7. PROPRIEDADE INTELECTUAL:',
                    text:[
                        '7.1. As marcas, nomes, logotipos, nomes de domínio e demais sinais distintivos, bem como todo e qualquer conteúdo, desenho, arte ou layout publicado no APLICATIVO e o próprio SERVIÇO, são de propriedade única e exclusiva da WESTARS TECHNOLOGY GROUP.',
                        '7.2. São expressamente vedados todos e quaisquer atos ou contribuições tendentes à descompilação, engenharia reversa, modificação das características, ampliação, alteração, mesclagem ou incorporação em quaisquer outros programas ou sistemas do APLICATIVO ou SERVIÇO. Enfim, toda e qualquer forma de reprodução do APLICATIVO ou SERVIÇO, total ou parcial, permanente, temporária ou provisória, de forma gratuita ou onerosa, sob quaisquer modalidades, formas ou títulos é expressamente vedada, respondendo o infrator por todos e quaisquer danos e/ou prejuízos, aí incluídos lucros cessantes de danos emergentes, causados à WESTARS TECHNOLOGY GROUP.'
                    ]
                },{
                    title: '8. CANCELAMENTO DO ACESSO AO APLICATIVO:',
                    text: [
                        '8.1. O USUÁRIO declara que está ciente e concorda que a WESTARS TECHNOLOGY GROUP, à sua livre discrição, poderá, a qualquer momento, cancelar sua utilização do SERVIÇO, incluindo, mas não se limitando: A) por descumprimento e/ou violação destes TERMOS; B) em decorrência de ordem judicial ou requisição legal e competente de autoridade governamental; C) por requisição do próprio USUÁRIO; D) por desativação ou modificação do SERVIÇO (ou de qualquer uma de suas partes); E) por caso fortuito, força maior e/ou questões de segurança; F) por inatividade da conta por um período razoável de tempo; G) pela suposta prática de qualquer atividade fraudulenta ou ilegal por parte do USUÁRIO, a critério exclusivo da WESTARS TECHNOLOGY GROUP; H) ao uso inadequado do APLICATIVO, a critério exclusivo da WESTARS TECHNOLOGY GROUP; e/ou I) por inadimplemento por parte do USUÁRIO de quaisquer obrigações, valores, pagamentos devidos em razão do SERVIÇO, quando aplicável.',
                        '8.2. O USUÁRIO declara que está ciente e concorda que o término de seu acesso ao SERVIÇO, por qualquer razão constante destes TERMOS, pode ocorrer sem uma notificação prévia e todas as informações e dados constantes poderão ser apagados, não fazendo jus o USUÁRIO ao recebimento de qualquer importância, seja a título de indenização, reembolso, compensação, ou outro de qualquer natureza e/ou espécie.'
                    ]
                },{
                    title: '9. DISPOSIÇÕES FINAIS:',
                    text: [
                        '9.1. Estes TERMOS constituem o acordo integral entre o USUÁRIO e a WESTARS TECHNOLOGY GROUP e regerão a utilização do SERVIÇO pelo USUÁRIO, substituindo qualquer acordo anterior.',
                        '9.2. Todas as notificações, intimações, ofícios ou qualquer outra forma oficial de cientificação da WESTARS TECHNOLOGY GROUP deverão ocorrer em seu endereço de sede, descrito no preâmbulo dos TERMOS.',
                        '9.3. Caso a WESTARS TECHNOLOGY GROUP deixe de exercer ou executar qualquer direito ou dispositivo destes TERMOS, isto não será caracterizado como uma renúncia a tal direito ou dispositivo, nem constituirá novação. Se qualquer dispositivo dos TERMOS for considerado inválido por um tribunal competente, as partes concordam que o restante dos TERMOS deverá ser interpretado de tal forma a refletir a intenção original, permanecendo os demais dispositivos dos TERMOS em pleno vigor e efeito.',
                        '9.4. O USUÁRIO não poderá ceder e tampouco transferir estes TERMOS, total ou parcialmente, sem prévia aprovação por escrito da WESTARS TECHNOLOGY GROUP. O USUÁRIO concede sua aprovação para que a WESTARS TECHNOLOGY GROUP ceda e transfira estes TERMOS, total ou parcialmente, para um adquirente das participações acionárias, negócios ou bens da WESTARS TECHNOLOGY GROUP, ou para um sucessor em razão de qualquer operação societária.',
                        '9.5. Não existe joint venture, sociedade, emprego ou relação de representação, ou de mandato entre o USUÁRIO, a WESTARS TECHNOLOGY GROUP ou qualquer ente público como resultado do contrato entre você e a WESTARS TECHNOLOGY GROUP ou pelo uso dos SERVIÇOS.',
                        '9.6. Caso qualquer disposição destes TERMOS seja tida como ilegal, inválida ou inexequível, total ou parcialmente, por qualquer legislação, essa disposição ou parte dela será, naquela medida, considerada como não existente para os efeitos destes TERMOS, mas a legalidade, validade e exequibilidade das demais disposições contidas nestes TERMOS não serão afetadas. Nesse caso, as partes substituirão a disposição ilegal, inválida ou inexequível, ou parte dela, por outra que seja legal, válida e exequível e que, na máxima medida possível, tenha efeito similar à disposição tida como ilegal, inválida ou inexequível para fins de conteúdo e finalidade dos presentes TERMOS. Estes TERMOS constituem a totalidade do acordo e entendimento das partes sobre este assunto e substituem e prevalecem sobre todos os entendimentos e compromissos anteriores sobre este assunto. Nestes TERMOS, as palavras “inclusive” e “inclui” significam “incluindo, sem limitação”.',
                        '9.7. Estes TERMOS e o relacionamento entre o USUÁRIO e a WESTARS TECHNOLOGY GROUP serão regidos pelas leis da República Federativa do Brasil. Fica eleito o foro da Comarca da Capital do Estado de São Paulo como sendo o único competente para dirimir quaisquer litígios e/ou demandas que venham a envolver as partes em relação ao uso e acesso de seu site e APLICATIVO, com expressa renúncia a qualquer outro por mais privilegiado que seja ou se torne. O USUÁRIO e a WESTARS TECHNOLOGY GROUP concordam em submeter-se à competência única e exclusiva dos tribunais localizados no Brasil.',
                        'ÚLTIMA ATUALIZAÇÃO: 01/08/2019'
                    ]
                }
            ]
        },
        termsMySquare:{
            title: '#EuCuidoDoMeuQuadrado',
            description: 'Evento realizado pelo Instituto Limpa Brasil para conscientização de consumo, separação, descarte correto de resíduos e mapeamento de pontos viciados de lixo.',
            content: [
                {
                    title: 'Atividade: Descarte irregular de lixo',
                    text: [
                        'Os pontos viciados de lixo são os locais eleitos pela sociedade para o descarte inadequados dos resíduos. Viu um lugar que recebe lixo constantemente? Envie uma foto e a localização pelo QZela registrando a ocorrência no segmento #EuCuidodoMeuQuadrado > Descarte irregular de lixo.',
                    ]
                },
                {
                    title: 'Atividade: Eu separo em casa  (Reciclável, Orgânico e Rejeito)',
                    text: [
                        'Separe seus resíduos em pelo menos 3 frações (reciclável, orgânico e rejeito). Registre a ocorrência no segmento #EuCuidodoMeuQuadrado > Eu separo em casa (Reciclável, Orgânico e Rejeito), tirando fotos ou grave um vídeo dos seus resíduos separados e destinados corretamente, indique o local no mapa e pronto! Descarte o resíduo reciclável no dia da coleta seletiva, ecoponto, leve a um catador ou cooperativa de reciclagem.',
                        'RECICLÁVEIS: Metais (como aço e alumínio), papel, papelão, tetrapak, diferentes tipos de plásticos e vidro.',
                        'DICA: Use o lixo da lavanderia (não esqueça de limpá-los adequadamente!)',
                        'ORGÂNICOS: Restos de alimentos e resíduos de jardim (folhas secas, podas, etc)',
                        'DICA: Use o lixo da cozinha.',
                        'REJEITOS: Resíduos não recicláveis como resíduos de banheiros (fraldas, absorventes, cotonetes, etc) e outros resíduos de limpeza.',
                        'DICA: Use o lixo do banheiro.',
                    ]
                },
                {
                    title: 'Termos e Condições: ',
                    text: [
                        'Ao participar do evento #EuCuidoDoMeuQuadrado, o usuário de QZela concorda com o compartilhamento de seus dados de cadastro e as imagens e vídeos registradas no app QZela junto ao Instituto Limpa Brasil e Gooders, responsável pela organização e coordenação do evento, para fins de divulgação e premiação realizadas pela entidade e pela empresa parceira Gooders para recebimento de pontos na plataforma. QZela compromete-se a compartilhar os dados estrita e exclusivamente com o Instituto Limpa Brasil e Gooders, respeitando as demais condições da Lei Geral de Proteção de Dados.',
                        'Confirme seu nome e email para participação no Evento.',
                    ]
                },
            ]
        },
        impactEveryDay:{
            title: ' Impact Every Day',
            description: 'O evento Impact Every Day tem o propósito de construir um mundo mais sustentável. Podemos fazer muito no nosso quadrado e cada ação conta. Faça parte do desafio #EuCuidoDoMeuQuadrado separando seus resíduos em casa. Saiba como participar:',
            content: [
                {
                    title: '1° Confirme sua participação no evento:',
                    text: [
                        '- Clique no botão "Participar" abaixo para aceitar os termos.',
                        '- Confirme seus dados preenchendo com nome, e-mail e o código ID enviado pela organização.',
                    ]
                },
                {
                    title: '2° Separe seu resíduo em pelo menos 3 frações:',
                    text: [
                        'RECICLÁVEIS: Metais (como aço e alumínio), papel, papelão, tetrapak, diferentes tipos de plásticos e vidro.',
                        'DICA: Use o lixo da lavanderia (não esqueça de limpá-los adequadamente!)',
                        'ORGÂNICOS: Restos de alimentos e resíduos de jardim (folhas secas, podas, etc)',
                        'DICA: Use o lixo da cozinha.',
                        'REJEITOS: Resíduos não recicláveis como resíduos de banheiros (fraldas, absorventes, cotonetes, etc) e outros resíduos de limpeza.',
                        'DICA: Use o lixo do banheiro.',
                    ]
                },
                {
                    title: '3° Registre no app:',
                    text: [
                        '- Na página inicial do app, clique no botão (+) e crie uma ocorrência na categoria #EuCuidodoMeuQuadrado > Eu separo em casa (Reciclável, Orgânico e Rejeito)',
                        '- Tire fotos ou grave um vídeo dos seus resíduos separados e destinados corretamente, indique o local no mapa e pronto!',
                    ]
                },
                {
                    title: '4° Descarte o resíduo reciclável no dia da coleta seletiva, ecoponto, leve a um catador ou cooperativa de reciclagem.',
                    text: [
                        'Os 80 funcionários mais engajados, ganharão uma muda de árvore e poderão nomeá-la. Essas mudas são nativas da Mata Atlântica em área situada no Parque Legado das águas sob gestão da Fundação Florestal e será plantada pelo Projeto Plantando o Futuro, parceria com reservas Votorantin, localizado no Vale do Ribeira no sul do Estado de São Paulo.',
                        'O último dia para inserir as ações no aplicativo, será no dia 30.08, e a divulgação dos resultados no dia 13.09',
                    ]
                },
                {
                    title: 'Termos e Condições: ',
                    text: [
                        'Ao participar do evento Impact Every Day, o usuário de QZela concorda com o compartilhamento de seus dados de cadastro e imagens e vídeos registrados no app QZela junto ao Instituto Limpa Brasil e Deloitte Touche Tohmatsu, responsáveis pela organização e coordenação do evento, para fins de divulgação e premiação realizadas pelas entidades. QZela compromete-se a compartilhar os dados estrita e exclusivamente com o Instituto Limpa Brasil e Deloitte Touche Tohmatsu, respeitando as demais condições da Lei Geral de Proteção de Dados.',
                        'Confirme seu nome, email e código ID para participação no Evento.',
                    ]
                },
            ]
        },
        success:{
            title: 'Sucesso!',
            text: 'Sua mensagem foi enviada com sucesso!',
            home: 'Home',
            again: 'Enviar outra mensagem',
        },
        email:{
            title: 'E-mail confirmado!',
            text: 'Se você não foi redirecionado de volta ao app ou está acessando pelo computador, tente realizar o login novamente, seu e-mail foi confirmado com sucesso.',
        },
        qrCode: {
            title: "Redirecionando..."
        },
        footer:{
            terms: 'Termos de uso',
            privacy: 'Política de privacidade'
        }
    },
    en: {
        current: 'english',
        menu: [
            {text: 'Home', link: '/'},
            {text: 'How It Works', link: '/#about'},
            {text: 'Institutional', link: '/#institutional'},
            {text: 'Managers', link: '/#gestores'},
            {text: 'News', link: '/news/'},
            {text: 'Contact', link: '/contact/'},
            {text: 'FAQ', link: '/faq/'},
            {text: 'Terms', link: '/terms/'},
            {text: 'Privacy', link: '/privacy/'},
            {text: 'Map', link: '/map/'},
        ],       
        home: {
            about:{
                title: 'The app for citizenship.',
                text1: 'Report the problems you find in the city using nothing but your phone.',
                text2: "The greatest data bank on urban management strengthens",
                text3: "the citizen's voice.",
                text4: 'Everyone wins.',
                more: 'Learn more'
            },
            hiw:{
                title: 'How it works',
                text: [
                    'Download QZela from App Store or Google Play. After a quick register, you may start pointing out urban maintenance and management problems anywhere with Internet access, straight from your mobile. It takes no longer than 30 seconds.',
                    'QZela has about 40 segments of urban management topics, detailed into over 400 types of problems that impact life in the city, and you will be able to take part in the process.',
                    'In your profile on QZela, you can check all of your own reports, and their status. Each and every activity add up to your score, and your poits can be redeemed in awards and benefits.'
                ]
            },
            testimony: [
                {
                    title: 'Innovation',
                    text: 'An intelligent public policy, at the reach of your hands. QZela is based on the concept of public policy applied to advanced technology with simple and friendly interface, so that the citizen can actively take part in urban maintenance demands.',
                    img: 'https://storage.googleapis.com/assets.qzela.com.br/images/landing/tecnologias/inovacao.png',
                    btn: 'Watch our institutional video'
                },
                {
                    title: 'Technology',
                    text: 'QZela uses Artificial Intelligence resources for data management. This ensures credibility and consistency of data, whether they are generated by the citizen or the public administration inspector.',
                    img: 'https://storage.googleapis.com/assets.qzela.com.br/images/landing/tecnologias/tecnologia.png',
                    btn: 'Watch our institutional video'
                },
                {
                    title: 'Transparency',
                    text: 'QZela praises for the construction of an extremely reliable, database, generating valuable information for public service management operations. QZela Corp allows integration with their clients’ systems, by means of APIs, which facilitates scheduling of services and grants more efficiency with cost reduction.',
                    img: 'https://storage.googleapis.com/assets.qzela.com.br/images/landing/tecnologias/transparencia.png',
                    btn: 'Watch our institutional video'
                }
            ],
            insitutional:{
                title: 'Institutional Vídeo',
                text: "Stay on top of QZela's features and utilities with examples of city usage."
            },            
            managers: {
                title: 'QZela Corp for managers',
                text: 'An unprecedented and effective service for substantial cost reduction',
                private: 'Private',
                public: 'Public'
            }
        },
        filter: "Filter",
        blog: ['Published by Cleci Leão.'],
        blogBoss: ['Published by Robero Badra.'],
        tags: ["Cases", "Cidadania", "Cidades Inteligentes", "Corona Vírus", "Gestão Eficiente", "Meio Ambiente", "Mobilidade Urbana", "Saúde e Bem-estar", "Segurança", "Tecnologia"],
        noResults: "No results found.",
        prevPage: "Previous Page",
        nextPage: "Next Page",
        managers:{
            _private:{
                title: 'Concessionaires and Service Providers',
                text: [
                    "QZela uses technology-powered mobility resources to build a data bank and consistent references for urban management.",
                    "By means of artificial intelligence, QZela is able to grant governments, concessionaires and service providers an unprecedented service, efficient and with substantial cost reduction.",
                    "With QZela, the manager will have a complete map of urban maintenance problems under their responsibility. The system offers up-to-data information in real time, by means of a collaborative app, with full participation of the population.",
                    "On QZela Corp, one can follow up, by means of updated reports, all demands generated in their area, and under their responsibility. Thanks to a thorough databank, one can reduce costs and anticipate better work plans, demonstrations and reports on the services provided.",
                    "We are collaborators, partners, professionals, citizens. In common, we hold the desire to go the extra mile; to build the future within the present. We bring innovative ideas close to the deepest respect for public welfare, adding to public policies our knowledge and background on private service management."
                ]
            },
            _public:{
                title: 'Public Administration',
                text: [
                    "QZela offers a tool that enables the citizen to gather accurate information, in real time, on every urban management problem. By means of technology and with friendly interface, directly used on citizens’ own mobile phones, QZela offers the best control over what happens in the city maintenance.",
                    "This allows the public management to better administrate the public space, either directly or by monitoring the work of third-parties. In real time, and with transparency, it is possible to know everything that is being pointed out as city problem. The tool still allows the manager to estimate costs with more accuracy, in addition to measure performance and demand results.",
                    "With the reports produced in real time at QZela’s back end, pubic management is made easier, with economy and more efficiency.",
                    "Everyone wins, after all."
                ]
            },
            contact:{
                title: 'Want to know more?',
                btn: 'Contact us'
            },
            corp:{
                title: 'Already a QZela customer?',
                btn: 'QZela Corp'
            },
            back: 'Back'
        },
        contact:{
            title: 'Get in Touch',
            name: 'Name',
            email:'E-mail',
            lblSubject: 'SUBJECT',
            subject: {
                normal: 'Contact',
                commercial: 'Commercial',
                press: 'Press'
            },
            company: 'Company',
            message:'Message',
            send: 'Send'
        },
        faq:{
            title: 'FAQ',
            text: [
                {
                    question: "How to download QZela?",
                    answer: "In case you have an Apple device (iPhone), click on the Apple Store icon, and type QZela. You will soon see the icon to download the app. If you’d rather use a direct link, please click here, from your mobile.",
                },
                {
                    question: "I can’t access the app. What can I do?",
                    answer: "Check your Internet connection where you are. Is it active? Verify if the Internet connection where you are has any type of blockage such as a firewall. Have you completed your initial register? You will only do it once, in your first access, and then you are ready to use the app.",
                },
                {
                    question: "After downloading the app, how do I file my report?",
                    answer: "At QZela’s initial screen, there is a red button with a + sign. By clicking on this button, you access the report category menu and select those you want to report. Next, you’ll make a photo or video of your report, and send them in. There! Your report is already filed.",
                },
                {
                    question: "What type of devices support QZela?",
                    answer: "QZela works exclusively on mobile phones. Tablets, PCs and Notebooks are not enabled for use.",
                },
                {
                    question: "Why should I fill in my CPF number on QZela’s register page?",
                    answer: "There are three primary reasons why QZela requests your CPF number. To ensure credibility of information; to guarantee your own security, so that another user is not able to misappropriate your data; to have your name linked to the score system when there are awards or advantages offered to users.",
                },
                {
                    question: "What is the screen “my profile” for?",
                    answer: "QZela app is more than a communication channel with a map of the city: it allows the citizen himself to follow up all reports he has filed, with photo and additional information. On the screen “My Profile”, the user will find reports they have interacted with, and monitor how many points they score, the “QZelas”.",
                },
                {
                    question: "Where does my report information go?",
                    answer: "Reports are registered in a data bank, managed by Artificial Intelligence, to which public managers may have access by hiring the company that develops QZela. Reports are sent out to managers in an anonymous way. Users’ each and every personal data is protected by law.",
                },
                {
                    question: "What is the coverage of QZela?",
                    answer: "QZela works nationally, throughout Brazil, in the first stage, but the system is dimensioned to work in all countries.",
                },
                {
                    question: "How to find a problem to report on the app?",
                    answer: "You can visualize the categories by side-scrolling on the app screens. Or, in case you prefer, you can also type in the problem you want to report, or yet click on the microphone available on your own keyboard, and speak out the problem, so that the app makes the search for you.",
                },
                {
                    question: "How to score in QZela?",
                    answer: 'Every interaction on the app adds up points to your score, the QZelas. Your points can be generic, or related to a specific event, concerning to the field "events". Check the equivalence of each interaction: File a report: 5 points. Confirm a report open by another user: 1 point. Invalidate an inexistent or incorrect report, registered as open: 1 point. Close an open report, which has already been solved: 5 points. Evaluate the service executed when the report is already solved and closed: 1 point.',
                },
                {
                    question: "How does the QZela score apply?",
                    answer: "QZela will convert the users’ score into rewards, advantages and discounts. Keep summing up! As soon as there are good news, you’ll be the first to learn!",
                },
                {
                    question: "What happens when the user opens an invalid occurrence?",
                    answer: "The invalid occurrence will be identified by Artificial Intelligence, and the user will soon be notified on the cancelling of their occurrence.",
                },
                {
                    question: "What makes a occurrence considered as invalid?",
                    answer: "Invalid occurrences are those with inconsistency or inappropriate input: photos that fail to identify the problem pointed out (such as reporting a broken traffic light and sending the image of a pot hole on the pavement, or other random object); inappropriate comments, images or exposure of people in embarrassing situations, addresses not matching georeferenced spot or  inexistence of the category where the report was filed (such as, for instance, pointing out “polluted beach” where there is no beach, geographically.",
                },
                {
                    question: "What happens when someone marks my occurrence as invalid?",
                    answer: "In case your occurrence is still active but it contains some sort of inconsistency, the system will analyze it and double check the data. In case it is considered actually invalid, your occurrence will be closed.",
                },
                {
                    question: "I am not able to file a occurrence, for it shows as “existing”.",
                    answer: "To avoid duplicity of inputs, each occurrence can only be open once. But don’t worry! You can still confirm an open occurrence, and score from this action.",
                },
                {
                    question: "What happens when someone confirms my occurrence?",
                    answer: "Having your occurrence confirmed gives more relevance to the problem pointed out, and occurrences gain priority in the electronic selection made by Artificial Intelligence.",
                },
                {
                    question: "Why confirm occurrences open by someone else?",
                    answer: "Confirming other people’s occurrences gives more relevance to the problem pointed out, and the information gains priority in the electronic selection made by Artificial Intelligence.",
                },
                {
                    question: "Why should I close occurrences?",
                    answer: "Closing occurrences reported and solved adds up points to your score and increases the user’s credibility. Attention though: close occurrences only when you find the problem solved. Otherwise, your confirmation will not be accepted by the Artificial Intelligence system, and your score can drop.",
                },
                {
                    question: "How will I know when the occurrence is already closed?",
                    answer: "When the occurrences was made by yourself, as soon as it is closed, the system will send you a notification. For occurrences still waiting for confirmation, one can check their own status on the app’s historic. Occurrences generated by other users can be checked whenever you are geographically close to the reported spot.",
                },
                {
                    question: "Why should I evaluate services executed in closed occurrences?",
                    answer: "Your assessment will be informed to managers, permanently ensuring improves on the services provided, by means of the attested quality of work delivered. Additionally, evaluating services also increases your score.",
                },
                {
                    question: "How to access QZela’s database?",
                    answer: "Behind QZela’s free app to the user, there is a platform of services dedicated to managers: QZela Corp. To access the data, you must hire the service, as an individual or a Corporation, after a careful analysis on what information the client wishes to obtain. Contact us and apply for an estimate!",
                },
                {
                    question: "What is QZela Corp?",
                    answer: "QZela Corp is a platform of urban maintenance that qualifies, classifies and organizes data generated by QZela app, with use of Artificial Intelligence.",
                },
                {
                    question: "How to create data reports on QZela Corp?",
                    answer: "By joining the QZela Corp platform, the user is granted detailed instructions on how to use data and create reports, graphics, tables or lists, according to their management or operational demands.",
                },
                {
                    question: 'What is the field "Events" for on the app?',
                    answer: "According to specific demands, QZela may promote actions, both individually or in collaboration with other companies, associations, organizations or communities. By registering as a participant in a certain event, users can benefit from the exclusive score linked to the action, as they interact on the app."
                }
            ],
            more: 'Want to know more?',
            contact: 'Contact Us',
        },
        privacy: {
            title:'QZela’s Privacy Policy',
            terms: [
                {
                    title: 'IMPORTANT INFORMATION:',
                    text: [
                        'This PRIVACY POLICY (“PRIVACY POLICY”) is part of the TERMS AND CONDITIONS OF USE and describes how WESTARS TG SERVIÇOS DE INFORMÁTICA LTDA. (“WESTARS TECHNOLOGY GROUP”) collects, stores, uses, processes, associates, protects, shares and treats information, including your personal data, in connection with the APP and services run under the TERMS AND CONDITIONS OF USE',
                        'This PRIVACY POLICY sets up the conditions of data treatment, required to allow the operation of QZELA APPLICATION. In case you are not in accordance with any disposition of this PRIVACY POLICY, you should not use the application. This instrument confirms that you are fully aware and in expressed agreement with the fact that WESTARS TECHNOLOGY GROUP will be able to collect, store, process, associate, share, use, publicize or any other means of treating your information, including personal data, under any forms and finalities foreseen in this PRIVACY POLICY.',
                        'Rules established in this PRIVACY POLICY are exclusive to the QZELA APPLICATION and the SERVICES described in TERMS AND CONDITIONS OF USE, exclusively provided by WESTARS TECHNOLOGY GROUP in Brazil.',
                        'By using the APPLICATION and SERVICES provided by WESTARS TECHNOLOGY GROUP, you state you fully agree with the rules contained in this PRIVACY POLICY, for which we recommend you to read it carefully and, in case of doubts, get in touch with us through the contact page in our website or through social medias.'
                    ]
                },
                {
                    title: '1. INFORMATION COLLECTED:',
                    text: [
                        'As you make your registration and use the SERVICES, you provide information, and WESTARS TECHNOLOGY GROUP collects statistic data regarding to SERVICES, added information and personal identification data. Such information starts then being treated under the terms of this PRIVACY POLICY and may be used in situations, and to purposes described in ITEM 3 below.',
                        'WESTARS TECHNOLOGY GROUP may collect information, including inputs that allow your identification, in a number of circumstances, such as: when creating your profile at WESTARS TECHNOLOGY GROUP, when you access the APPLICATION, or when you use services provided by WESTARS TECHNOLOGY GROUP.',
                        'By registering at WESTARS TECHNOLOGY GROUP, you provide certain information that may include your name, address, phone number, e-mail, CPF identification number, gender, date of birth, RG and address confirmation. Other information may be collected and processed by WESTARS TECHNOLOGY GROUP in accordance with applicable lawful regulations.',
                        'WESTARS TECHNOLOGY GROUP also requires you to provide access to your camera, approximate location (nonstop), storage, phone, user name and password.',
                        'As you make use of QZELA APPLICATION and SERVICES described in the TERMS AND CONDITIONS OF USE, additional information concerning to you may be collected by WESTARS TECHNOLOGY GROUP, such as your geographical position, your IP address, browser used, information obtained by cookies, type and brand of your mobile phone, mobile device identifiers, version of your operational system, apps installed, information on network, provider and Internet connection used, device settings, software data, itinerary, evaluations you make on SERVICES provided by WESTARS TECHNOLOGY GROUP and the means of communication with WESTARS TECHNOLOGY GROUP and/or with third-parties.',
                        'In case you do not provide the information required, WESTARS TECHNOLOGY GROUP will not be able to ensure quality and accuracy of QZELA APPLICATION and the SERVICES as described in the TERMS AND CONDITIONS OF USE. In this case, WESTARS TECHNOLOGY GROUP is entitled to have you immediately removed from QZELA APPLICATION, for reasons that include enduring regular and safe functionality to QZELA APPLICATION and SERVICES described in the TERMS AND CONDITIONS OF USE to the remaining users.',
                        'WESTARS TECHNOLOGY GROUP may also obtain information on you from public and private data banks, commercial partners and/or other service providers when you link your ACCOUNT to those providers (“THIRD PARTIES”), regarding that these THIRD PARTIES have obtained information under the terms of applicable laws. Such information may be associated to further information WESTARS TECHNOLOGY GROUP collects on you, and also used in situations / for purposes described in ITEM 3 below. Information provided by THIRD PARTIES to WESTARS TECHNOLOGY GROUP follow their own discipline established by such THIRD PARTIES and may be subjected to their respective privacy policy.'
                    ]
                },
                {
                    title: '2. PROTECTION AND STORAGE:',
                    text: [
                        'A WESTARS TECHNOLOGY GROUP deploys appropriate information security practices in all of its systems and infrastructure, including physical and digital security means, in order to guarantee protection to your information. Such means include access control, strong cryptography, SSL implementation, firewalls, alteration log register, double authentication factor, among other protocols and mechanisms.',
                        'The information collected by WESTARS TECHNOLOGY GROUP may be stored in servers located in Brazil and abroad, exclusive at discretion of WESTARS TECHNOLOGY GROUP. Hereby, you declare to be fully in agreement with the fact that other countries may have data protection levels other than Brazil’s. With no jeopardy, your information by any chance stored in other country will be subjected to security measures at least equivalent to those described in the previous paragraph.',
                        'Security measures described above are applicable to your information only from the moment WESTARS TECHNOLOGY GROUP receives them and keeps them under its safeguard. Both the functioning and security of the device you use to access QZELA APPLICATION and the SERVICES described in the TERMS AND CONDITIONS OF USE, as well as the security of the network through which data is transmitted are not under responsibility of WESTARS TECHNOLOGY GROUP, remaining as your own responsibility, as a user, to adopt and respect basic security precautions.'
                    ]
                },
                {
                    title: '3. USE AND SHARING:',
                    text: [
                        'Your information, including personal data, are used, among other purposes, to allow and improve QZELA APP’s functions and the SERVICES as described in the TERMS AND CONDITIONS OF USE.',
                        'WESTARS TECHNOLOGY GROUP will make use of your information, specifically to the following purposes: A) provide, supply, keep, develop, improve and expand the use of QZELA APP and SERVICES as described in the TERMS AND CONDITIONS OF USE; B) identify your profile and personalize your experience and use of QZELA APP and SERVICES as described in the TERMS AND CONDITIONS OF USE; C) generate analysis and statistic reports of the functioning and operation of QZELA APP and SERVICES as described in the TERMS AND CONDITIONS OF USE, to the benefit of WESTARS TECHNOLOGY GROUP and/or its partners; D) allow WESTARS TECHNOLOGY GROUP to get in touch with you concerning to the QZELA APP and SERVICES as described in the TERMS AND CONDITIONS OF USE, as well as to provide support to the user; E) to offer and improve security and monitoring resources concerning to the QZELA APP and SERVICES as described in the TERMS AND CONDITIONS OF USE; F) to spot and protect both WESTARS TECHNOLOGY GROUP and its users against frauds, abuses or illegal acts; G) to stop and prevent the use of apps and other fraudulent programs, or that may change any functionalities of the QZELA APP and SERVICES as described in the TERMS AND CONDITIONS OF USE; H) to develop new products and services, as well as functionalities; I) to provide researches and scientific analysis; and J) to meet legal obligations of data collection and sharing before governmental demands, under the terms of applicable laws and regulations.',
                        'Your contact data is used so that WESTARS TECHNOLOGY GROUP can communicate directly with you to send you relevant information, including notifications on the use of QZELA APP and SERVICES as described in the TERMS AND CONDITIONS OF USE.',
                        'WESTARS TECHNOLOGY GROUP may use other information on you available from other sources and combine them with the information directly collected from you, in compliance with this PRIVACY POLICY, to all ends foreseen by this ITEM. In this case, only the information directly collected by WESTARS TECHNOLOGY GROUP from you and the result of this combination will be covered and subjected by this PRIVACY POLICY.',
                        'WESTARS TECHNOLOGY GROUP will not share or provide to third parties any of your information, including personal data, except in the following cases: A) when you require or authorize WESTARS TECHNOLOGY GROUP to have them shared; B) to make feasible the service and operation of QZELA APP and SERVICES as described in the TERMS AND CONDITIONS OF USE in a proper way, and under the terms you have hired it to; C) to commercial partners who provide services or work in charge of WESTARS TECHNOLOGY GROUP, according to the specifications and guidelines determined by WESTARS TECHNOLOGY GROUP, including requirement to foresee, by contract, the maintenance of confidentiality of such information by third parties; D) to authorities and other governmental entities, to meet applicable law and regulations concerning to QZELA APP and SERVICES as described in the TERMS AND CONDITIONS OF USE; E) to authorities and other governmental entities, including natural or juridical persons, in compliance with legal obligations or lawful requirement; F) to police authorities or representatives of Governmental Departments, such as the National District Attorney regardless of legal obligation or juridical demand, when, at WESTARS TECHNOLOGY GROUP’s discretion, there is any suspect, flagrant or criminal investigation including violence or severe threaten involving users of the QZELA APP, or in case there is any need to protect rights or safety of WESTARS TECHNOLOGY GROUP, their users or third parties; G) to the exercise and defense of any rights of WESTARS TECHNOLOGY GROUP, at its discretion, including judicial or administrative processes; and H) in case of ownership or control alternance at WESTARS TECHNOLOGY GROUP’s administration, due to merges, acquisitions or asset sales.'
                    ]
                },
                {
                    title: '4. CONSENT:',
                    text: [
                        'WESTARS TECHNOLOGY GROUP offers its SERVICES subjected to TERMS AND CONDITIONS OF USE and the PRIVACY POLICY and this will be applicable for the time you use the QZELA APP and SERVICES as described in the TERMS AND CONDITIONS OF USE.',
                        'By installing QZELA APP and concluding your registration, you automatically agree with the TERMS AND CONDITIONS OF USE and the PRIVACY POLICY. THIS MEANS YOU EXPRESSLY AGREE WITH DATA COLLECTION, STORAGE, USE, PROCESSING, ASSOCIATION, SHARING, DIVULGATION AND OTHER MEANS OF INFORMATION TREATMENT, INCLUDING PERSONAL DATA, UNDER ANY FORMS AND TO ALL PURPOSES FORESEEN IN THIS PRIVACY POLICY.',
                        'Future changes in this PRIVACY POLICY that may be considered relevant that is, that may reduce or change rights attributed to you will be communicated in advance by WESTARS TECHNOLOGY GROUP. In this case, if you do not agree with the content of the new PRIVACY POLICY, your information will remain being treated in compliance with the previous version for the time of notice, period when you may cease to use QZELA APP and SERVICES as described in the TERMS AND CONDITIONS OF USE. If you continue to use QZELA APP and SERVICES as described in the TERMS AND CONDITIONS OF USE after the time of notice is terminated, you will automatically fully agree with the new PRIVACY POLICY, which will then start to rule over the information management system.'
                    ]
                },
                {
                    title: '5. PERSONAL DATA EXCLUSION:',
                    text: [
                        'In case you desire to exclude your personal data under administration of WESTARS TECHNOLOGY GROUP, you must require the exclusion directly to WESTARS TECHNOLOGY GROUP through the contact page on our website.',
                        'In order to proceed with such exclusion, you must delete your QZELA APP and get in touch with WESTARS TECHNOLOGY GROUP to require that your information is permanently eliminated. Therefore, deleting the QZELA APP is not enough to have your information excluded.',
                        'In attendance of the applicable law or judicial order, WESTARS TECHNOLOGY GROUP may keep certain data of yours in storage, even after your exclusion requirement.',
                        'WESTARS TECHNOLOGY GROUP will store your exclusion and, when observed the legal period of compulsory storage of certain data, the company will provide destruction or anonymity, at its own discretion, of any information that may identify you.',
                        'The present PRIVACY POLICY will be fully interpreted according to Brazilian legislation, written in Portuguese, and any litigation or dispute will be resolved in the courthouse of the city of São Paulo, state of São Paulo, Brasil, expressly renouncing to any other court, regardless of any privilege or adequation.',
                        'In case of doubts or suggestions, please feel free to contact WESTARS TECHNOLOGY GROUP on our contact page at the website or through social medias.',
                        'Last updated: 01/Aug/2019'
                    ]
                },
            ]
        },
        terms: {
            title: 'QZela Terms and Conditions of Use',
            content: [
                {
                    title:'1. INTRODUCTION AND GENERAL PROVISIONS:',
                    text: [
                        '1.1. These are the TERMS OF USE ("TERMS") that govern access and use, within Brazil, of services provided through technological application ("APPLICATION"), websites and related content ("SERVICE (S)") , made available by WESTARS TG SERVIÇOS DE INFORMÁTICA LTDA. (“WESTARS TECHNOLOGY GROUP”), registered with CNPJ under number 33.286.389 / 0001-32, with headquarters at Flórida Street, 1.758, cj 91 - J. Monções, Zip Code 04565-001, São Paulo, SP, a any and all individuals who use the APPLICATION and / or the SERVICES ("USER").',
                        '1.2. ACCEPTANCE OF TERMS AND CONDITIONS OF USE: WHEN REGISTERING AND CONTINUOUSLY USING THE APPLICATION AND SERVICES, THE USER WILL DECLARE, IN EXPRESS AND INEQUIGUE FORM, HAVE READ AND ACCEPT THE TERMS. IF, AT ANY TIME, THE USER DOES NOT AGREE TO THE TERMS, SHALL IMMEDIATELY END THE USE OF THE APPLICATION AND UNINSTALL IT FROM YOUR DEVICE.',
                        '1.3. COMMUNICATION WITH THE USER: The USER authorizes WESTARS TECHNOLOGY GROUP to send administrative notifications in the APPLICATION (“push”), by email, SMS, in a publication on its website or by any other available means of communication (“MEDIA OF COMMUNICATION” ”) With content of an informative and promotional nature related to the SERVICES.',
                        "1.4. CHANGES TO TERMS: WESTARS TECHNOLOGY GROUP reserves the right to make changes and updates to the TERMS, at any time, without the need for prior notice. In the event of changes to the TERMS that restrict USERS 'rights, WESTARS TECHNOLOGY GROUP will communicate such change, at its discretion, through the MEDIA. However, this liberality does not remove the responsibility of the USER to periodically check the TERMS. If the USER does not agree with the changes to the TERMS, he must cancel his account, cease all and any use of the SERVICES and uninstall the APPLICATION from his device. The fact that the USER continues to access or use the SERVICES after this posting represents his consent to be bound by the amended TERMS."
                    ]
                },
                {
                    title:'2. USER DATA AND PRIVACY:',
                    text: [
                        '2.1. WESTARS TECHNOLOGY GROUP has an express policy on privacy. Registration information and any other information about the USER is subject to the treatment mentioned in such privacy policy. The USER is fully aware and agrees that the use of the APPLICATION and the provision of the SERVICE involve the collection and use of information and data about the USER (as defined in the PRIVACY POLICY, which is an integral part of this TERM, AND WHICH SHALL BE CONSULTED BY THE USER ON THE FOLLOWING LINK, including the transfer of this information and data to other territories, for the purposes of storage, processing and use by WESTARS TECHNOLOGY GROUP, aiming at providing the service and other purposes provided for in the PRIVACY POLICY.'
                    ]
                },
                {
                    title:'3. REGISTRATION:',
                    text: [
                        "3.1. To use the APPLICATION, the USER must register and maintain a personal USER account (“ACCOUNT”). The USER must have civilian capacity, having at least 18 years of age to create his profile, unless he is emancipated or has obtained full civilian capacity under the terms of the civil legislation in force. Parents and legal guardians are responsible for assessing the suitability of using mobile devices and the SERVICES for minors under 18 years of age. The USER who wishes to use the SERVICE must fill in the required registration fields and will be responsible for the veracity of the information declared, being obliged to maintain valid, updated and correct information. The USER profile is exclusive and non-transferable. In the event of non-confirmation of your data, the USER's access to the SERVICE may be blocked, at the sole discretion of WESTARS TECHNOLOGY GROUP.",
                        "3.2. ACCOUNT information is the sole responsibility of whoever entered it. In the event of causing damages or losses of any kind, the appropriate measures can be taken by WESTARS TECHNOLOGY GROUP in order to safeguard your interests and the integrity of the other USERS of the APPLICATION."
                    ]
                },
                {
                    title:'4. SERVICES:',
                    text: [
                        "4.1. SERVICES PROVIDED BY WESTARS TECHNOLOGY GROUP: The SERVICES consist of providing a technological application that allows the USER to be properly registered: to generate and monitor the occurrence of urban janitorial services. The SERVICE must not be used for any purpose other than those expressly authorized by these TERMS.",
                        "4.1.1. The use of the SERVICES by the USERS is, in general, free. WESTARS TECHNOLOGY GROUP will previously inform the USER about features and conducts that may generate charges and / or payments by the USER in a specific way through the MEDIA and / or by updating the TERMS. WESTARS TECHNOLOGY GROUP reserves the right to start charging for the SERVICE or part of it at any time. The USER will be previously informed if this occurs and will have the opportunity to consent to such charges for the SERVICE or to stop using it.",
                        "4.1.2. The USER declares that he is fully and aware and agrees that WESTARS TECHNOLOGY GROUP does not provide and does not guarantee the provision of any public service, exercised, directly or indirectly, by federal, state, municipal and / or district entities, rendering, exclusively, a intermediation service, aimed at facilitating the interaction between USERS and public entities or bodies, public service concessionaires or civil society organizations responsible for managing citizens' demands.",
                        "4.1.3. The management and solution of the demands of citizens, Users of the Application and of the Services, are the sole and exclusive competence of public entities or agencies, public service concessionaires or relevant civil society organizations, with WESTARS TECHNOLOGY GROUP not having any responsibility and / or species.",
                        "4.2. WESTARS TECHNOLOGY GROUP provides the USER with the option of evaluating and qualitatively commenting on the service provided by the corresponding public entity, being prohibited, under penalty of canceling the use of the APPLICATION and SERVICES, pursuant to CLAUSE 8, the publication of defamatory comments , libelous, violent, obscene, pornographic, racist, homophobic, illegal or otherwise offensive, as understood by WESTARS TECHNOLOGY GROUP at its sole discretion.",
                        "4.2.1. The content published by the USER is his sole and exclusive civil and criminal liability and for all legal purposes, including in relation to comments and opinions about certain content.",
                        "4.3. ACCESS TO THE NETWORK AND EQUIPMENT: THE USER understands and agrees that the use of the SERVICE requires the purchase of mobile telephony devices and the contracting of telecommunication services, and that the use of the SERVICE may generate charges by such telecommunication service providers. for connecting to the Internet, for example. The USER is exclusively responsible for contracting and bearing any and all costs and charges related to the acquisition of his mobile phone device and the contracting of his telecommunication service. WESTARS TECHNOLOGY GROUP is not responsible for the availability, quality and maintenance of such telecommunication services and the USER understands that the conditions of the telecommunications service may affect the experience of the SERVICE. WESTARS TECHNOLOGY GROUP will not be held responsible for any problem related to the SERVICE arising directly or indirectly from inconsistencies or failures in the mobile phone devices and / or in the telecommunication services contracted by the USER.",
                        "4.4. MODIFICATION OR TERMINATION OF THE SERVICE: WESTARS TECHNOLOGY GROUP reserves the right, at any time, to modify or discontinue, temporarily or permanently, the SERVICE or part of it, with or without notice. The USER agrees that WESTARS TECHNOLOGY GROUP will not be liable, nor will it have any additional obligations, implicit or explicit, to the USER or third parties due to any modification, suspension or deactivation of the SERVICE."
                    ]
                },
                {
                    title:'5. LICENSE:',
                    text: [
                        '5.1. Subject to compliance with these TERMS, WESTARS TECHNOLOGY GROUP grants the USER a free, limited, non-exclusive, personal, non-transferable and revocable license at any time, solely and exclusively to access and use the SERVICE for personal, non-commercial purposes, through from any compatible device.'
                    ]
                },
                {
                    title:'6. EXCLUSION OF WARRANTIES AND LIABILITY:',
                    text: [
                        "6.1. THE USER DECLARES, IN AN EXPRESS AND UNEQUISIBLE WAY, THAT HE IS FULLY AWARE AND AGREES THAT: A) THE USE OF THE SERVICE IS UNDER THE USER'S ENTIRE RESPONSIBILITY; B) THE SERVICE IS PROVIDED TO THE USER AS IS AVAILABLE; C) WESTARS TECHNOLOGY GROUP DOES NOT PROVIDE ANY WARRANTIES OTHER THAN THOSE EXPRESSLY ESTABLISHED IN THESE TERMS; AND D) WESTARS TECHNOLOGY GROUP CANNOT GUARANTEE THAT: (I) THE SERVICE AVAILABLE WILL MEET THE USER'S NEEDS; (II) THE SERVICE WILL BE PROVIDED UNINTERRUPTED OR TIMELY; (III) THAT THE PUBLIC ENTITY RESPONSIBLE FOR THE PROVISION OF THE PUBLIC SERVICES DEMANDED BY THE USER WILL PROVIDE IT IN A TEMPORARY WAY AND, VERY LESS, ACCORDING TO THE USER'S EXPECTATION; AND (IV) WHAT IMPROVEMENTS OR INNOVATIONS WILL BE IMPLEMENTED.",
                        "6.1.1. THE USER DECLARES, EXPRESSLY AND UNEQUISELY, THAT HE IS FULLY AWARE AND AGREES THAT WESTARS TECHNOLOGY GROUP SHALL HAVE NO RESPONSIBILITY, CONTRACTUAL OR EXTRACONTRACTUAL, FOR ANY PATRIMONIAL OR MINOR DAMAGES, WHATSOEVER, LIMITATION, , OF ANY NATURE AND / OR KIND, RESULTING FROM: A) USE OR INABILITY TO USE THE SERVICE; B) BREACHES OF SECURITY AND UNAUTHORIZED ACCESS TO TRANSMISSIONS OR USER INFORMATION, AS WELL AS AMENDMENTS TO THESE; C) THIRD PARTY GUIDELINES OR CONDUCT ABOUT THE SERVICE; AND D) FOR REASONS FOR FORCE MAJEURE OR FORCED CASE, OR STILL, ARISING FROM ACTS PERFORMED BY THE USER OR / OR ACTS PERFORMED BY OR UNDER THE RESPONSIBILITY OF THIRD PARTIES.",
                        "6.2. The USER agrees to indemnify and exempt WESTARS TECHNOLOGY GROUP and its directors and employees, partners and customers who display their trademark, for any and all losses and damages that may be claimed, including, but not limited to, legal expenses and attorneys' fees , due to: A) any unlawful fact or act practiced by the USER; B) use of the SERVICES; C) breach of these TERMS; or D) violation of the rights of any third parties."
                    ]
                },
                {
                    title:'7. INTELLECTUAL PROPERTY:',
                    text: [
                        "7.1. The brands, names, logos, domain names and other distinctive signs, as well as any and all content, design, art or layout published in the APPLICATION and the SERVICE itself, are the sole and exclusive property of WESTARS TECHNOLOGY GROUP.",
                        "7.2. Any and all acts or contributions aimed at decompiling, reverse engineering, modification of characteristics, expansion, alteration, merging or incorporation in any other programs or systems of the APPLICATION or SERVICE are expressly prohibited. Anyway, any and all forms of reproduction of the APPLICATION or SERVICE, total or partial, permanent, temporary or provisional, free of charge or costly, under any modalities, forms or titles are expressly prohibited, the infringer is liable for any and all damages and / or losses, including lost profits from emerging damages, caused to WESTARS TECHNOLOGY GROUP."
                    ]
                },
                {
                    title:'8. CANCELLATION OF ACCESS TO THE APPLICATION:',
                    text: [
                        "8.1. The USER declares that he is aware and agrees that WESTARS TECHNOLOGY GROUP, at its free discretion, may, at any time, cancel its use of the SERVICE, including, but not limited to: A) for noncompliance and / or violation of these TERMS; B) as a result of a court order or legal and competent request from governmental authority; C) at the request of the USER; D) by deactivating or modifying the SERVICE (or any of its parts); E) by chance, force majeure and / or security issues; F) for inactivity of the account for a reasonable period of time; G) for the alleged practice of any fraudulent or illegal activity by the USER, at the sole discretion of WESTARS TECHNOLOGY GROUP; H) the inappropriate use of the APPLICATION, at the exclusive discretion of WESTARS TECHNOLOGY GROUP; and / or I) if the USER fails to comply with any obligations, amounts, payments due due to the SERVICE, when applicable.",
                        "8.2. The USER declares that he is aware and agrees that the termination of his access to the SERVICE, for any reason contained in these TERMS, can occur without prior notification and all information and constant data may be erased, the USER is not entitled to receive any importance, whether as indemnity, reimbursement, compensation, or other of any nature and / or kind."
                    ]
                },
                {
                    title:'9. FINAL PROVISIONS:',
                    text: [
                        "9.1. These TERMS constitute the entire agreement between the USER and WESTARS TECHNOLOGY GROUP and will govern the use of the SERVICE by the USER, replacing any previous agreement.",
                        "9.2. All notifications, subpoenas, letters or any other official form of scientific knowledge from WESTARS TECHNOLOGY GROUP must take place at its headquarters address, described in the preamble to the TERMS.",
                        "9.3. If WESTARS TECHNOLOGY GROUP fails to exercise or enforce any right or provision of these TERMS, this will not be characterized as a waiver of such right or provision, nor will it constitute a novation. If any provision of the TERMS is found to be invalid by a competent court, the parties agree that the remainder of the TERMS must be interpreted in such a way as to reflect the original intention, the remaining provisions of the TERMS remaining in full force and effect.",
                        "9.4. The USER may not assign or transfer these TERMS, in whole or in part, without prior written approval from WESTARS TECHNOLOGY GROUP. The USER grants his approval for WESTARS TECHNOLOGY GROUP to assign and transfer these TERMS, in whole or in part, to a purchaser of WESTARS TECHNOLOGY GROUP's shareholdings, businesses or assets, or to a successor due to any corporate transaction.",
                        "9.5. There is no joint venture, partnership, employment or representation relationship, or mandate between the USER, WESTARS TECHNOLOGY GROUP or any public entity as a result of the contract between you and WESTARS TECHNOLOGY GROUP or through the use of the SERVICES.",
                        '9.6. If any provision of these TERMS is considered to be illegal, invalid or unenforceable, totally or partially, by any legislation, that provision or part of it will, to that extent, be considered as non-existent for the purposes of these TERMS, but the legality, validity and enforceability of the other provisions contained in these TERMS will not be affected. In this case, the parties will replace the illegal, invalid or unenforceable provision, or part of it, with another that is legal, valid and enforceable and that, to the maximum extent possible, has an effect similar to the provision considered illegal, invalid or unenforceable for the purposes of content and purpose of these TERMS. These TERMS constitute the entire agreement and understanding of the parties on this matter and supersede and prevail over all previous understandings and commitments on this matter. In these TERMS, the words "including" and "includes" mean "including, without limitation".',
                        "9.7. These TERMS and the relationship between the USER and WESTARS TECHNOLOGY GROUP will be governed by the laws of the Federative Republic of Brazil. The jurisdiction of the District of the Capital of the State of São Paulo is elected as being the only one competent to settle any disputes and / or demands that may involve the parties in relation to the use and access of its website and APPLICATION, with express waiver of any other however privileged it is or becomes. The USER and WESTARS TECHNOLOGY GROUP agree to submit to the sole and exclusive jurisdiction of the courts located in Brazil.",
                        "LAST UPDATED: 08/01/2019"
                    ]
                },
            ]
        },
        termsMySquare:{
            title: '#EuCuidoDoMeuQuadrado',
            description: 'Evento realizado pelo Instituto Limpa Brasil para conscientização de consumo, separação, descarte correto de resíduos e mapeamento de pontos viciados de lixo.',
            content: [
                {
                    title: 'Atividade: Descarte irregular de lixo',
                    text: [
                        'Os pontos viciados de lixo são os locais eleitos pela sociedade para o descarte inadequados dos resíduos. Viu um lugar que recebe lixo constantemente? Envie uma foto e a localização pelo QZela registrando a ocorrência no segmento #EuCuidodoMeuQuadrado > Descarte irregular de lixo.',
                    ]
                },
                {
                    title: 'Atividade: Eu separo em casa  (Reciclável, Orgânico e Rejeito)',
                    text: [
                        'Separe seus resíduos em pelo menos 3 frações (reciclável, orgânico e rejeito). Registre a ocorrência no segmento #EuCuidodoMeuQuadrado > Eu separo em casa (Reciclável, Orgânico e Rejeito), tirando fotos ou grave um vídeo dos seus resíduos separados e destinados corretamente, indique o local no mapa e pronto! Descarte o resíduo reciclável no dia da coleta seletiva, ecoponto, leve a um catador ou cooperativa de reciclagem.',
                        'RECICLÁVEIS: Metais (como aço e alumínio), papel, papelão, tetrapak, diferentes tipos de plásticos e vidro.',
                        'DICA: Use o lixo da lavanderia (não esqueça de limpá-los adequadamente!)',
                        'ORGÂNICOS: Restos de alimentos e resíduos de jardim (folhas secas, podas, etc)',
                        'DICA: Use o lixo da cozinha.',
                        'REJEITOS: Resíduos não recicláveis como resíduos de banheiros (fraldas, absorventes, cotonetes, etc) e outros resíduos de limpeza.',
                        'DICA: Use o lixo do banheiro.',
                    ]
                },
                {
                    title: 'Termos e Condições: ',
                    text: [
                        'Ao participar do evento #EuCuidoDoMeuQuadrado, o usuário de QZela concorda com o compartilhamento de seus dados de cadastro e as imagens e vídeos registradas no app QZela junto ao Instituto Limpa Brasil e Gooders, responsável pela organização e coordenação do evento, para fins de divulgação e premiação realizadas pela entidade e pela empresa parceira Gooders para recebimento de pontos na plataforma. QZela compromete-se a compartilhar os dados estrita e exclusivamente com o Instituto Limpa Brasil e Gooders, respeitando as demais condições da Lei Geral de Proteção de Dados.',
                        'Confirme seu nome e email para participação no Evento.',
                    ]
                },
            ]
        },
        impactEveryDay:{
            title: ' Impact Every Day',
            description: 'O evento Impact Every Day tem o propósito de construir um mundo mais sustentável. Podemos fazer muito no nosso quadrado e cada ação conta. Faça parte do desafio #EuCuidoDoMeuQuadrado separando seus resíduos em casa. Saiba como participar:',
            content: [
                {
                    title: '1° Confirme sua participação no evento:',
                    text: [
                        '- Clique no botão "Participar" abaixo para aceitar os termos.',
                        '- Confirme seus dados preenchendo com nome, e-mail e o código ID enviado pela organização.',
                    ]
                },
                {
                    title: '2° Separe seu resíduo em pelo menos 3 frações:',
                    text: [
                        'RECICLÁVEIS: Metais (como aço e alumínio), papel, papelão, tetrapak, diferentes tipos de plásticos e vidro.',
                        'DICA: Use o lixo da lavanderia (não esqueça de limpá-los adequadamente!)',
                        'ORGÂNICOS: Restos de alimentos e resíduos de jardim (folhas secas, podas, etc)',
                        'DICA: Use o lixo da cozinha.',
                        'REJEITOS: Resíduos não recicláveis como resíduos de banheiros (fraldas, absorventes, cotonetes, etc) e outros resíduos de limpeza.',
                        'DICA: Use o lixo do banheiro.',
                    ]
                },
                {
                    title: '3° Registre no app:',
                    text: [
                        '- Na página inicial do app, clique no botão (+) e crie uma ocorrência na categoria #EuCuidodoMeuQuadrado > Eu separo em casa (Reciclável, Orgânico e Rejeito)',
                        '- Tire fotos ou grave um vídeo dos seus resíduos separados e destinados corretamente, indique o local no mapa e pronto!',
                    ]
                },
                {
                    title: '4° Descarte o resíduo reciclável no dia da coleta seletiva, ecoponto, leve a um catador ou cooperativa de reciclagem.',
                    text: [
                        'Os 80 funcionários mais engajados, ganharão uma muda de árvore e poderão nomeá-la. Essas mudas são nativas da Mata Atlântica em área situada no Parque Legado das águas sob gestão da Fundação Florestal e será plantada pelo Projeto Plantando o Futuro, parceria com reservas Votorantin, localizado no Vale do Ribeira no sul do Estado de São Paulo.',
                        'O último dia para inserir as ações no aplicativo, será no dia 30.08, e a divulgação dos resultados no dia 13.09',
                    ]
                },
                {
                    title: 'Termos e Condições: ',
                    text: [
                        'Ao participar do evento Impact Every Day, o usuário de QZela concorda com o compartilhamento de seus dados de cadastro e imagens e vídeos registrados no app QZela junto ao Instituto Limpa Brasil e Deloitte Touche Tohmatsu, responsáveis pela organização e coordenação do evento, para fins de divulgação e premiação realizadas pelas entidades. QZela compromete-se a compartilhar os dados estrita e exclusivamente com o Instituto Limpa Brasil e Deloitte Touche Tohmatsu, respeitando as demais condições da Lei Geral de Proteção de Dados.',
                        'Confirme seu nome, email e código ID para participação no Evento.',
                    ]
                },
            ]
        },
        success:{
            title: 'Success!',
            text: 'Your message has been sent successfully',
            home: 'Home',
            again: 'Send another message',
        },
        email:{
            title: 'Your email has been confirmed!',
            text: 'If you have not been redirected back to the app or are accessing from your computer, try logging in again, your email has been successfully confirmed.',
        },
        qrCode: {
            title: "Redirecting..."
        },        
        footer:{
            terms: 'Terms of use',
            privacy: 'Privacy policy'
        }
    },
}

export default defaultLang;